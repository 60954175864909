import {Injectable, EventEmitter} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Paginator } from "../main/models/paginator";
import { ResponseContentType } from "@angular/http";
import { map } from "rxjs/operators";
// import 'rxjs/add/operator/do';

@Injectable()
export class DataService {

  private API_URL: string;
  SITE_TYPE:string;
  domainTypeImg: string = '';
  domainTypeName: string = '';
  domainTypeMyName: string = '';
  domainRaportChange: string = '';
  constructor(
    private http: HttpClient,
    private config: AppConfig,

  ) {
    this.API_URL = this.config.getConfig('API_URL');
    this.SITE_TYPE = this.config.getConfig('SITE_TYPE');
    this.checkDomainType();
  }

  checkDomainType() {
    if (this.SITE_TYPE === 'oddzwonimy') {
      this.domainTypeImg = '/assets/images/Oddzwonimy_logo_pl.svg';
      this.domainTypeName = 'Oddzwonimy';
      this.domainTypeMyName = 'Moje Oddzwonimy';
      this.domainRaportChange = 'Statystyki';
    } else  {
      this.domainTypeImg = '/assets/images/fastcall-logo.png';
      this.domainTypeName = 'FastCall';
      this.domainTypeMyName = 'Moj FastCall';
      this.domainRaportChange = 'Raporty';
    }
  }

  getDashboardData(start = null, end = null, callbox = null) {  // '3, 2, 467, 826, 791'
    let search = "";
    if (start || end) search = "date_start="+start+"&date_end="+end;
    if (callbox) search = search+"&callbox="+callbox;
    return this.http.get(this.API_URL+"dashboard/?"+search);
  }

  getCalls(paginator:Paginator, date_start = '', date_end = '', status = '', msisdn = '', agent = '', callboxId = '') {
    let offset = paginator.itemsPerPage * paginator.currentPage;
    status = "status="+status;
    date_start = "&date_start="+date_start;
    date_end = "&date_end="+date_end;
    msisdn = "&msisdn="+msisdn;
    agent = "&agent="+agent;
    callboxId = "&callbox="+callboxId;
    return this.http.get(this.API_URL+"calls/?"+status+date_start+date_end+msisdn+agent+callboxId+"&limit="+paginator.itemsPerPage+"&offset="+offset);
  }

  getNotification(paginator:Paginator, date_start = '', date_end = '', sender = '', search = '', callboxId = '') {
    let offset = paginator.itemsPerPage * paginator.currentPage;
    let senderUrl = "";
    // if (sender && parseInt(sender) >= 0)
    if (sender != '' && sender != '0') senderUrl = "&sender="+sender;
    if (callboxId != '' && callboxId != '0') callboxId = "&callbox="+callboxId;
    else { callboxId = '' }
    date_start = "&date_start="+date_start;
    date_end = "&date_end="+date_end;
    // console.log("callboxId", callboxId);
    let searchUrl = "";
    if (search) searchUrl = "&search="+search;
    return this.http.get(this.API_URL+"notification/?"+date_start+date_end+callboxId+senderUrl+searchUrl+"&limit="+paginator.itemsPerPage+"&offset="+offset);
  }

  getSenders(){
    return this.http.get(this.API_URL+"notification/senders/?limit=1000");
  }

  getCallboxes(){
    return this.http.get(this.API_URL+"callbox/?limit=1000");
  }

  getCallboxDefaults(){
    return this.http.get(this.API_URL+"callbox/defaults/");
  }

  handleError(){}
  getRecords(id:number){
    return this.http.get(this.API_URL+"call-records/"+id+"/",{responseType: 'blob'});
  }

  sendEmail(callboxId, email){
    return this.http.get(this.API_URL+"callbox/code2email/"+callboxId+"/?email="+email);
  }

  toggleCallboxActivity(id, isActive){
    return this.http.put(this.API_URL+"callbox-visible/"+id+"/", {'is_visible':isActive});
  }

  toggleAgentActivity(id, isActive){
    return this.http.put(this.API_URL+"callbox-agent-online/"+id+"/", {'is_online':isActive});
  }

  editCallbox(callbox){
    return this.http.put(this.API_URL+"callbox/"+callbox.id+"/", callbox);
  }

  saveNewCallbox(callbox){
    return this.http.post(this.API_URL+"callbox/", callbox);
  }

  deleteCallbox(callboxId){
    return this.http.delete(this.API_URL+"callbox/"+callboxId+"/");
  }

  getReportCalls(start = null, end = null){
    let search = "";
    if (start || end) search = "date_start="+start+"&date_end="+end;

    return this.http.get(this.API_URL+"report/calls/?"+search,{responseType: 'blob'});
  }
  getReportNumbers(start = null, end = null){
    let search = "";
    if (start || end) search = "date_start="+start+"&date_end="+end;
    return this.http.get(this.API_URL+"report/msisdn/?"+search,{responseType: 'blob'});
  }

  changePassword(old_password, new_password){
    const data = {
      old_password: old_password,
      new_password: new_password
    }
    return this.http.post(this.API_URL+"user/change-password/", data);
  }

}





    // calls/?status=&msisdn=&date_end=&date_start=&agent=&limit=20&offset=20
    // status = "status="+status;
    // date_start = "&date_start="+date_start;
    // date_end = "&date_end="+date_end;
    // msisdn = "&msisdn="+msisdn;
    // agent = "&agent="+agent;
