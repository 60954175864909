import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {DataService} from '../../services/data.service';
import {AppService} from '../../services/app.service';
import {PopupDefaultComponent} from './popup-default.component';
import {Callbox} from '../models/callbox';
import {NgForm} from '@angular/forms';
import {Utils} from '../../shared/utils/utils';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-dialog-install-fastcall',
  templateUrl: './dialog-install-fastcall.component.html',
  styles: []
})
export class DialogInstallFastcallComponent extends PopupDefaultComponent implements OnInit{

  @ViewChild('form') form: NgForm;
  // @ViewChild('emailField') emailField: HTMLFormElement;

  sendEmailView = false;
  showCode = false;
  showInstruction = false;
  callbox : Callbox;
  email: string = "";
  emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  domainTypeImg: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogInstallFastcallComponent>,
    private dataService: DataService,
    private appService: AppService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(dialogRef);
    this.callbox = dialogData['data'];
    this.domainTypeImg = this.dataService.domainTypeImg;
  }

  ngOnInit() {
  }

  toggleCode(){
    this.showCode = !this.showCode;
    if (this.showCode) this.showInstruction = false;
  }

  toggleInstruction(){
    this.showInstruction = !this.showInstruction;
    if (this.showInstruction) this.showCode = false;
  }

  mailSending = false;
  showError = false;
  sendEmail(){
    if (this.form.valid){
      this.showError = false;
      // console.log('Valid');
      this.mailSending = true;

      this.dataService.sendEmail(this.callbox.id, this.email).subscribe( data=>{
        this.afterEmail();
        this.snackBar.open("Wiadomość została wysłana", "Zamknij", {
          duration: 1000,
        });
      },error=>{
        this.afterEmail();
        this.snackBar.open("Nie udało się wysłać wiadomości", "Zamknij", {
          duration: 1500,
        });
        console.error("sendEmail: ",error);
      })
    }else{
      this.showError = true;
      // console.log('invalid');
    } // email invalid
  }

  copy(){
    Utils.copyToClipboard(this.callbox.embed_code_display);

    this.snackBar.open("Kod został skopiowany", "Zamknij", {
      duration: 1500,
    });
  }

  afterEmail(){
    this.closeDialog();
    this.sendEmailView = false;
    this.mailSending = false;
  }

}
